@import '../node_modules/bootstrap/scss/bootstrap.scss';
// @import './assets/scss/custom.scss';

//Import Light mode
@import './assets/scss/bootstrap.scss';
@import './assets/scss/style.scss';

//Import Dark RTL mode
// @import './assets/scss/bootstrap-dark.scss';
// @import './assets/scss/style-dark.scss';
// @import './assets/scss/style-rtl.scss';

//Import RTL mode
// @import './assets/scss/bootstrap.scss';
// @import './assets/scss/style.scss';
// @import './assets/scss/style-rtl.scss';

// Import Dark Mode
// @import './assets/scss/bootstrap-dark.scss';
// @import './assets/scss/style-dark.scss';

//features scss
@import './assets/scss/features';

// drawer
.react-drawer-drawer {
  z-index: 9999;
  background: #fff !important;
  width: 350px !important;
}

.drawer-main-menu {
  padding-top: 8px;
  padding-bottom: 8px;
}

.drawer-menu a i {
  min-width: 2rem;
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 1.25rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #7f8387;
  // transition: all 0.4s;
}

.drawer-menu a {
  display: block;
  padding: 0.625rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-size: 13px;
  // transition: all 0.4s;
}

.drawer-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.ReactModal__Overlay {
  z-index: 5000 !important;
}

.react-drawer-overlay {
  z-index: 1050;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #0a58ca;
  background-color: transparent;
}

.custom-badge-color {
  &.bg-soft-primary {
    color: $primary;
    background-color: rgba($primary, 0.1) !important;
  }
}

.carousel-overlay {
  .carousel-item {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $overlay;
    }
  }
}

.wicon a i {
  align-items: center;
  display: flex;
}

#style-switcher {
  transition: all 0.4s;
  background: none repeat scroll 0 0 $white;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  left: -189px;
  position: fixed;
  top: 11%;
  width: 189px;
  z-index: 9999;
  padding: 10px 5px;

  .bottom a.settings {
    background: none repeat scroll 0 0 $white;
    height: 41px;
    position: absolute;
    right: -41px;
    top: 100px;
    width: 40px;
    padding: 3px;
    border-radius: 0 30px 30px 0;
  }
}

.cookie-popup.alert {
  .btn-close {
    top: 17px;
    right: 17px;
  }
}
.fadeText1 {
  animation: fadeIn 5s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.4;
    }
    70% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
}
.fadeText2 {
  animation: fadeIn 5s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.4;
    }
    70% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
}
.textAbout {
  // padding:0px 0px 0px 0px;
  margin: 0px auto;
}
.imgTrans {
  transition: transform 5s ease-in-out;
  -webkit-transition: all 5s ease-in-out;
  transition-timing-function: linear, ease-in-out;
  transition-duration: 5s;
  animation: fadeIn 5s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 0.3;
    }
    50% {
      opacity: 0.4;
    }
    70% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
}
.nav-sticky{
  .navigation-menu {
    li {
      a {
        // color: rgb(152, 201, 244) !important;
       color: black !important;
        transition: color 0.1s,background-color 0.1s,padding 0.2s ease-in;
      }
    }
    // li:hover,
    // li.active {
    //   a {
    //     color:white !important;
    //     text-decoration: none;
    //     transition: color 0.1s, background-color 0.1s;
    //   }
      a::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 3px;
        left: 0;
        height: 3px;
        width: 100%;
        top:65px;
        // margin-top:4px;
        background-color: rgb(38, 138, 193);
        transform-origin: right top;
        transform: scale(0, 1);
        transition: color 0.3s,transform 0.3s ease-out;
    }
    a:active::before {
        // background-color: rgb(31, 160, 211);
    }
    a:hover::before, a:focus::before {
      color: red !important;
        transform-origin: left top;
        transform: scale(1, 1);
    }
     
    }
    
  }
// }

// #topnav .navigation-menu > li:active > a {
//   color:#f75d0a !important;
// }
#topnav .navigation-menu > li:hover > a {
  color:#2f55d4 !important;
}

// #topnav.nav-sticky  .navigation-menu > li:active > a {
//   color: #cf4051 !important;
// }
.bg-secondary-y{
  // background-color: #ffffff50 !important;
  .navigation-menu {
    li {
      a {
        color: black!important;
        transition: color 0.1s,background-color 0.1s,padding 0.2s ease-in;
      }
      a::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 3px;
        left: 0;
        height: 3px;
        width: 100%;
        top:65px;
        // margin-top:4px;
        background-color:#2F55D4;
        transform-origin: right top;
        transform: scale(0, 1);
        transition: color 0.3s,transform 0.3s ease-out;
    }
    a:active::before {
      // background-color: rgb(31, 160, 211);
  }
  a:hover::before, a:focus::before {
    color: red !important;
      transform-origin: left top;
      transform: scale(1, 1);
  }
    }
}
}

.bg-secondary-x {
  background-color:  white !important;
  .navigation-menu {
    li {
      a {
        color: black !important;
       transition: color 0.1s,background-color 0.1s,padding 0.2s ease-in;
      }
    }
    li:hover,
    li.active {
      a {
        // color:#00FFFF !important;
          text-decoration: none;
          transition: color 0.1s, background-color 0.1s;
        }
      }
      a::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 3px;
        left: 0;
        height: 3px;
        width: 100%;
        top:65px;
        // margin-top:4px;
        background-color: #2F55D4;
        transform-origin: right top;
        transform: scale(0, 1);
        transition: color 0.3s,transform 0.3s ease-out;
    }
    a:active::before {
      // background-color: rgb(31, 160, 211);
  }
  a:hover::before, a:focus::before {
    color: red !important;
      transform-origin: left top;
      transform: scale(1, 1);
  }
    }
    
  }

.bg-secondary-z {
  background-color: white !important;
  .navigation-menu {
      li {
        a {
          color: rgb(20, 18, 18) !important;
         transition: color 0.1s,background-color 0.1s,padding 0.2s ease-in;
        }
      }
      li:hover
    {
        a {
          // color:rgb(205, 17, 17) !important;
            text-decoration: none;
            transition: color 0.1s, background-color 0.1s;
          }
        }
        a::before {
          content: '';
          display: block;
          position: absolute;
          bottom: 3px;
          left: 0;
          height: 3px;
          width: 100%;
          top:65px;
          // margin-top:4px;#2f55d4
          background-color: #2f55d4;
          transform-origin: right top;
          transform: scale(0, 1);
          transition: color 0.3s,transform 0.3s ease-out;
      }
      a:active::before {
        // background-color: rgb(31, 160, 211);
    }
    a:hover::before, a:focus::before {
      // color: red !important;
        transform-origin: left top;
        transform: scale(1, 1);
    }
      }
      
    }



 

// .slick-arrow.slick-prev,
// .slick-arrow.slick-next {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 20px;
//   height: 17px;
//   z-index: 1;
//   margin-top: -40px;
// }

// .slick-slider {
//   position: relative;
//   height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 0 40px;
// }
// .slick-list {
//   position: relative;
//   display: block !important;
//   overflow: hidden;
//   // padding: 100px !important;
//   width: 100%;
// }

// .slick-slide >div {
//   margin: 0 30px;
  
// }
// .slick-list {
//   margin: 0 -5px;
//   overflow: hidden;
// }
// .slick-prev{
//   position: absolute;
//   left: 3% !important;
// }
// .slick-next{
//   position: absolute;
//   right:3% !important;
// }
@media(max-width:991px){
  .nav-sticky{
    .navigation-menu {
      li {
        a {
          // color: rgb(152, 201, 244) !important;
         color: black !important;
          transition: color 0.1s,background-color 0.1s,padding 0.2s ease-in;
        }
      }
      // li:hover,
      // li.active {
      //   a {
      //     color:white !important;
      //     text-decoration: none;
      //     transition: color 0.1s, background-color 0.1s;
      //   }
        a::before {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          height: 3px;
          width: 100%;
          top:30px;
          // margin-top:4px;
          background-color: rgb(38, 138, 193);
          transform-origin: right top;
          transform: scale(0, 1);
          transition: color 0.3s,transform 0.3s ease-out;
      }
      a:active::before {
          // background-color: rgb(31, 160, 211);
      }
      a:hover::before, a:focus::before {
        color: red !important;
          transform-origin: left top;
          transform: scale(1, 1);
      }
       
      }
      
    }
}
@media(max-width:991px){
  .bg-secondary-x {
    // background-color: #ffffff50 !important;
    .navigation-menu {
      li {
        a {
          color: white !important;
         transition: color 0.1s,background-color 0.1s,padding 0.2s ease-in;
        }
      }
      li:hover,
      li.active {
        a {
          // color:#00FFFF !important;
            text-decoration: none;
            transition: color 0.1s, background-color 0.1s;
          }
        }
        a::before {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          height: 3px;
          width: 100%;
          top:30px;
          // margin-top:4px;
          background-color: rgb(38, 138, 193);
          transform-origin: right top;
          transform: scale(0, 1);
          transition: color 0.3s,transform 0.3s ease-out;
      }
      a:active::before {
        // background-color: rgb(31, 160, 211);
    }
    a:hover::before, a:focus::before {
      color: red !important;
        transform-origin: left top;
        transform: scale(1, 1);
    }
      }
      
    }
  
}
@media(max-width:991px){
  .bg-secondary-y{
    // background-color: #ffffff50 !important;
    .navigation-menu {
      li {
        a {
          color: black!important;
          transition: color 0.1s,background-color 0.1s,padding 0.2s ease-in;
        }
        a::before {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          height: 3px;
          width: 100%;
          top:30px;
          // margin-top:4px;
          background-color: rgb(38, 138, 193);
          transform-origin: right top;
          transform: scale(0, 1);
          transition: color 0.3s,transform 0.3s ease-out;
      }
      a:active::before {
        // background-color: rgb(31, 160, 211);
    }
    a:hover::before, a:focus::before {
      color: red !important;
        transform-origin: left top;
        transform: scale(1, 1);
    }
      }
  }
  }
}
.gray{
  filter:grayscale(100%);
}
.gray:hover{
  filter:none;
}
// .slick-arrow-icon-left, 
// .slick-arrow-icon-right {
//   position: absolute;
//   display: block;
//   cursor: pointer;
//   background: transparent;
//   color: black;
//   top: 35%;
//   -webkit-transform: translate(0, -50%);
//   -ms-transform: translate(0, -50%);
//   transform: translate(0, -50%);
//   padding: 0;
//   border: none;
//   outline: none;
//   transition: 0.5s ease-in-out;
//   &:hover,
//   &:focus {
//     outline: none;
//     background: transparent;
//     color: black;
//     font-size: 40px;
//     &::before {
//       opacity: 1;
//     }
//   }
// }

// .slick-arrow-icon-left {
//   left: 1px;
//   [dir="rtl"] & {
//     left: auto;
//     right: -50px;
//   }
// }

// .slick-arrow-icon-right {
//   right: 6px;
//   [dir="rtl"] & {
//     left: -50px;
//     right: auto;
//   }
  
//   }
// .avatar{
//   transition: transform 0.2s ease-in-out;
// } 
// .avatar:hover{
//   transform: rotate(360deg);
// }



  .avatar.avatar-ex-sm {
    max-height: 35px;
}
.icons{
  transition: transform 0.2s ease-in-out;
} 
.icons:hover{
  transform: rotate(360deg);
}
.photos img{
  // width:15%;
  aspect-ratio:3/2;
  object-fit:contain;
  mix-blend-mode:color-burn;
}
.slick-list{
  
  margin-top: -112px !important;

}
.btn.btn-sm
{
  font-size: 13px!important;
  font-weight: 700;
 } 
 .btn.btn-sm:hover{
  padding: 7px 16px;
  font-size: 10px;
  /* color: red; */

  background-color: #2f55d473;
  text-decoration:none!important;
}
.ph{
  margin-top:-43px;
  aspect-ratio:1/1;
  object-fit:contain;
  width:100px;
   height:250px;
  //  background-color: transparent;
  //  mix-blend-mode: multiply;
}
@media (max-width: 991px){
#topnav .navigation-menu {
    float: none;
}
}
// @media (max-width: 991px) {
//   #topnav .navigation-menu{
//     float: right;
//     width: 100%;
//     height:auto;
//     // color:blue;
//   }
// }
// @media (max-width: 991px){
// .bg-home {
//     margin-top: 181px;
//     padding: 150px 0;
//     height: auto;
//     // width:500px;
// }
// }
@media (max-width: 991px){
#topnav .navigation-menu > li > a {
    /* color: #3c4858; */
    padding: 10px 20px;
    color: blue!important;
}
}
// @media (max-width: 767px){
//   .bg-half-170{
//   padding: 150px 0;
//   height: auto;
//   margin-top: 180px;
// }
// }

@media(max-width:991px){
  .team{
    display:flex;
    justify-content:center;
  }
}
@media(max-width:991px){
  .partner{
    margin-left: -393px;
  }
}
@media (max-width: 991px){
#topnav {
    background-color: #dce3f9;
    // box-shadow: 0 0 3px rgb(60 72 88 / 15%);
    min-height: 74px;
    position:fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 999;
    // background-color: transparent;
    border: 0;
    transition: all .5s ease;
}
}
@media (max-width: 991px){
#topnav.nav-sticky {
  background: #ffffff;
  // box-shadow: 0 0 3px rgb(60 72 88 / 15%);
}
}
@media (max-width: 768px){
.section-title .title {
    font-size: 24px !important;
    margin-top:60px;
}
}


// @media(max-width:991px){
//   .rounded {
//     border-radius: 6px !important;
//     width: 253px;
//     margin-left: -49px;
// }
// }
@media(max-width:991px){
  .features.feature-full-bg {
    transition: all 0.5s ease;
    margin-left: 1px;
}
}
@media(max-width:991px){
  .card .card-body {
    padding: 1.5rem;
    margin-left: 10px;
}
}
@media(max-width:991px){
.para-desc {
  max-width: 400px;
  padding:0px 21px;
}
}
// @media (max-width: 991px){
// .card .card-body {
//     padding: 1.5rem;
//     margin-left: -1px;
// }
// }
.team1{
  display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 100px;
    @media (max-width: 991px){
      display: flex;
    flex-direction: column;
    justify-content: center;
    
}
}
@media (max-width: 991px){
.cookie-popup {
  left: -61px;
  position: fixed;
  width: 300px;
  height: 114px;
}
}
.aboutdescr{
display: flex;
margin-top: 35px;
    @media(max-width:991px){
    display: block;
    margin-top: 35px;
}
  
}
@media(max-width:991px){
  .me-auto {
    margin-right: auto !important;
    width: 340px;
}
}
@media(max-width:991px){
  .map iframe {
    width: 100%;
    height: 400px;
    margin-left: 25px;
}
}
@media(max-width:991px){
  .blog1 {
    border-radius: 6px !important;
    width: 300px;
    margin-left: -108px;
}
}
.togglericon{
  float:right;
  @media(max-width:991px){
    float:left
  }
}
@media (max-width: 991px){
.descr {
    max-width: 400px;
    margin-left: 0px !important;
}
}
@media(max-width:991px){
  .bcard {
    transition: all 0.5s ease;
    margin-left: -2px !important;
}
}
@media(max-width:991px){
  .titledescr {
    max-width: 400px;
    padding: 0px 69px;
    margin-left: -26px;
}
}
@media(max-width:991px){
  .bannertext {
    max-width: 400px;
    padding: 0px 44px;
}
}
@media(max-width:991px){
  .feat {
    max-width: 400px;
    margin-left:-21px;
}
}
@media(max-width:991px){
  .workdescr {
    max-width: 400px;
    padding: 0px 25px;
}
}
@media(max-width:991px){
  .carddescr {
    max-width: 400px;
    padding: 0px 28px;
    margin-left: -42px;

}
}
@media(max-width:991px){
  .imgResp{
    overflow: hidden;
    width: 100%;
    margin: 0px 19px;
}
}

:root{
    overflow-x: hidden;
 }
 @media(max-width:991px){
 .cookieres{
  // margin-left:801px;
  margin-left:-5px;
  width:50%;
 }
}
@media(max-width:991px){
  .linkres{
   // margin-left:801px;
   width:100%;
  }
 }
 @media(max-width:991px){
  .privacyeresp{
   // margin-left:801px;
   margin-left:88px;
  }
 }
 @media(max-width:991px){
  .privacy1sresp{
   margin-left: 118px;
  display:flex;
  justify-content:flex-start;
  }
  .terms1sresp{
    margin-left: 33px;
   display:flex;
   justify-content:flex-start;
   }
 }
 @media(max-width:991px){
  .partnerimageresponse{
 display: inline-block;
 max-height: 160px;
 width: auto;
 
 max-width: 500px;
 }
}
@media(max-width:991px){
.headingresponse{
 margin-left:-85px;
}
}
// #topnav .navigation-menu > li > a:active {
//   color: #d48a2f!important;
// }
// #topnav .nav-sticky.navigation-menu > li > a:active {
//   color: #d48a2f!important;
// }
 @media(max-width:991px){
  .linkrespons{
    display: flex;
    flex-direction: column;
    margin: 0px -35px;
  }
 }
 .productsec{
  display: grid;
  grid-template-columns: repeat(2,560px);
  gap:5px;
 }
 .carousel .control-dots .dot {
  transition: opacity .25s ease-in;
  opacity: .3;
  filter: alpha(opacity=30);
  // box-shadow: 1px 1px 2px rgb(0 0 0 / 90%);
  background: #1c1b1b !important;
  border-radius: 50%;
  width: 14px !important;
  height: 14px !important;
  cursor: pointer;
  display: inline-block;
  // margin: -5px 10px !important;
}


 @media(max-width:991px){
  .responsivebutton{
    display:flex;
    justify-content: space-evenly;
  }
  }
  .calendly-inline-widget {
    height: 720px !important;
    margin-top: 40px;
}
.carousel .slider-wrapper {
  overflow: hidden;
  margin: auto;
  width: 100%;
  transition: height .15s ease-in;
  margin-bottom: 49px !important;
  // margin-left: -60px !important;
}
.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  transition: all .25s ease-in;
  opacity: .4;
  filter: alpha(opacity=40);
  position: absolute;
  z-index: 0 !important;
  top: 20px;
  background: none;
  border: 0;
  font-size: 32px;
  cursor: pointer;
  display: none;
}

.kNBmiK {
  overflow: hidden;
  margin: 0 63px 15px 220px!important;
  // width:100vw !important;
  // margin-left: 394px;
}
.jWpUJH {
  margin-left: -60px;
}


.productpageImage{
  position:absolute;
 
  // top:260px;
  z-index:10;
  margin-left: -77px;
  height:30px;
  width:30px;
}
.productpageImage1{
  position:absolute;
 
  // top:260px;
  z-index:10;
  margin-left: -65px;
  height:30px;
  width:30px;
}
.TimeLine{
  position:relative;
  max-width:1200px;
  margin:100px auto;
  // margin-top: -55px; //Top Section Margin//
}
.TimeLine1{
  position:relative;
  max-width:1200px;
  margin:100px auto;
}
.sticky-top {
  top: 100px;
}
// .TimeLine::after{
//   content:'';
//   position: absolute;
//   width:2px;
//   height:103%;
//   background:#120303;
//   top:0;
//   left:50%;
//   border-bottom: 1px dotted;
//   margin-left: -62px;
//   z-index:-1;
  
// animation:moveline 20s linear forwards;
// }
// .TimeLine1::after{
//   content:'';
//   position: absolute;
//   width:2px;
//   height:103%;
//   background:#120303;
//   top:0;
//   left:50%;
//   border-bottom: 1px dotted;
//   margin-left: -62px;
//   z-index:-1;
//   animation:moveline 20s linear forwards;
// }
@keyframes moveline {
  0%{
    height: 0;
  }
  100%{
    height: 100%;
  }
}
.ContainerProduct{
  position:relative;
  animation:movedown 10s linear forwards;
  opacity:0;
}
@keyframes movedown {
  0%{
    opacity:1;
    transform: translateY(-30px);
  }
  100%{
    opacity: 1;
    transform: translateY(0px);
    
  }
}
.ContainerProduct:nth-child(1){
  animation-delay: 0s;
}
.ContainerProduct:nth-child(2){
  animation-delay: 1s;
}
.ContainerProduct:nth-child(3){
  animation-delay: 2s;
}
.ContainerProduct:nth-child(4){
  animation-delay: 3s;
}
.ContainerProduct:nth-child(5){
  animation-delay: 3s;
}
.demo4 {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  // height: 700px;
  position: relative;
}

.demo4-inner {
  overflow: hidden;
  position: relative;
  margin: auto;
}

.demo4-photo {
  position: absolute;
  background-color: lightgray;
}
.product12{
  width: 444px;
}
.slick-initialized .slick-slide {
  margin-top:120px;
}
.bqyzQp {
    position: relative;
    max-width: 1206px;
        margin-left: -123px;
} 
@media(max-width:900px)
{
.kNBmiK {
    overflow-x: hidden!important;
    // margin: 0;
    -webkit-scroll-snap-type: x mandatory;
    -moz-scroll-snap-type: x mandatory;
    -ms-scroll-snap-type: x mandatory;
    scroll-snap-type: x mandatory;
    -webkit-scrollbar-width: none;
    -moz-scrollbar-width: none;
    -ms-scrollbar-width: none;
    scrollbar-width: none;
    // display: grid !important;
    width:358px;
}
}
.jWpUJH {
    bottom: 9px !important;

    right: -48px!important;
}
@media (max-width: 767px){

  .jWpUJH {
      display: flex!important;
}
}
@media(max-width:900px){
  .calendlyresponse{
    margin-top: -50px!important;
    margin-left: 44px;
  }
}
._3efP_GeH5kyBAzqnLzL.cllbjvXCdYDt9A3te4cz {
  max-width:999px !important;
}
.title-heading .heading {
    font-size: 40px !important;
    letter-spacing: 1px;
}


// .membersButton:hover{
//     background- color:red;
// }

@media(max-width:991px){
.dashedlineresponsive{
  left: 5px !important;
}
}
@media(max-width:991px){
  .dashedlineresponsive2{
    left: 5px !important;
  }
  }

  @media(max-width:991px){
    .avatarresponsive{
      left: 10px !important;
    }
    }

@media(max-width:991px){
.headingresponisve{
  text-align: center;
  white-space: pre-wrap;
  color: grey;
  margin-left: 100px !important;
  width: 174px !important;
}
}

@media(max-width:991px){
.headingresponisve2{
  text-align: center;
  white-space: pre-wrap;
  margin-left: 76px;
  width: 216px;
}
}

@media(max-width:991px){
.imageresponsive{
  width: 85% !important;
  position: sticky !important;
  width: 50% !important;
}
}
@media(max-width:991px){
.section3heading{
  text-align: center;
  white-space: pre-wrap;
  margin-left: 92px;
}
}
@media(max-width:991px){
.section3heading2{
white-space: pre-wrap;
    color: grey;
    font-size: x-large;
    margin-left: 56px;
    width: 227px;
}
}

@media(max-width:991px){
.paragraphresponsive{
margin-left: 10px !important;
margin-bottom: 150px;
width: 350px !important;
margin-top: 190px;
}
}
.section {
    padding: 100px 0px 10px 0px;
    position: relative;
}






